@tailwind base;
@tailwind components;
@tailwind utilities;

.slick-dots {
  display: none !important;
}

.slick-prev,
.slick-next {
  z-index: 1;
}

.slick-prev:before,
.slick-next:before {
  display: none;
}

.slick-slide {
  padding-bottom: 10px;
  /* Reduced padding since we're removing dots */
}

/* Ensure arrows are visible on all backgrounds */
.slick-prev,
.slick-next {
  background-color: rgba(255, 255, 255, 0.7) !important;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.slick-prev:hover,
.slick-next:hover {
  background-color: rgba(255, 255, 255, 0.9) !important;
}